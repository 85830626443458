import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
    Koti: "/",
    "Analytiikka-konsultointi": "/fi/analytiikka-konsultointi",
    "Data-kerros": "/fi/data-kerros"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

  const DataLayer = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Data-kerros selitetty opetusohjelman kanssa [2020]"
          description="Data-kerros on peruskäsite kaikissa ammattimaisissa web-analytiikkajärjestelmissä. Mutta mitä se itse asiassa on ja miten sen toteutamme?"
          lang="fi"
          image="data-layer-code-hero-image3"
          alternateLangs={alternateLangs}
          canonical="/fi/data-kerros"
          datePublished="2024-06-18T04:32:43.188Z"
          dateModified="2024-06-18T15:21:48.881Z"
        />
        <MainContent article>
          <ImgScreenshot
            src="data-layer/data-layer-code-hero-image3.png"
            alt="Google Tag Manager Data-kerroksen visualisointi selainkonsolissa"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Data-kerros</H>
          <p>
  Yhteydessä tagien hallintaan ja web-analytiikkaan olet ehkä kuullut termin <b>data-kerros</b>. Se on 
  peruselementti kunnianhimoisessa web-analytiikkajärjestelmässä, koska kaikki datapisteet ja seurantasäännöt 
  riippuvat siitä.
</p>
<p>
  Siksi digitaalianalytiikan alalla sitä pidetään ehdottomana vaatimuksena mille tahansa analytiikkajärjestelmälle. 
  On kuitenkin myös tilanteita, joissa se ei ole välttämätön.
</p>
<p>
  Siksi haluan selittää <Link to="/fi/data-kerros">mikä data-kerros on</Link>, 
  sen <Link to="/fi/data-kerros">hyödyt</Link> ja erot 
  <Link to="/fi/data-kerros">Google Tag Managerin data-kerroksen</Link> ja 
  <Link to="/fi/data-kerros">Adobe Launchin</Link> välillä.
</p>
<p>
  Sen jälkeen tarkastelemme suosituimpien Tag Management -järjestelmien (TMS) toteutusta. Selitän 
  <Link to="/fi/data-kerros">suunnitteluvaiheen</Link>, jota seuraa 
  <Link to="/fi/data-kerros">toteutus</Link> ja <Link to="/fi/data-kerros">debuggaus</Link>.
</p>
<H as="h2">Mikä on data-kerros?</H>
<p>Data-kerros on <b>datarakenne</b>, joka tarjoaa olennaista tietoa avain-arvo-pareina käytettäväksi esimerkiksi 
  <Link to="/fi/tagien-hallinta">tagien hallintajärjestelmissä</Link>.
</p>
<p>Data-kerros on saatavilla sivuston globaalissa JavaScript-scope:ssa <b>Array</b>- tai <b>Object</b>-muodossa ja 
  pitää tietoja rakenteellisessa muodossa muiden ohjelmien käytettäväksi.
</p>
<p>Data-kerroksen etuna on ohjelmallisesti yksinkertainen pääsy olennaisiin tietoihin sivustovierailun aikana.
</p>
<p>
  Se mahdollistaa pääsyn tietoihin yhdessä keskipisteessä ja on perustana data-analytiikan logiikalle 
  <Link to="/fi/tagien-hallinta">tagien hallintajärjestelmässä</Link>.
</p>
<SnippetJS caption="Koodiesimerkki data-kerroksen luomiseksi Google Tag Managerille. Se luo JavaScript-objektin array:ssä (!) ja pitää kaikki olennaiset mitat myöhempää data-analyysia ja sääntöjen luomista varten tagien hallintajärjestelmässä.">
  {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];`}
</SnippetJS>
<p>
  Koska sitä käytetään tietojen tallentamiseen useista tietolähteistä, se yksinkertaistaa nykyisten tietojen 
  arvojen seurantaa, koska vain yhtä paikkaa täytyy tarkkailla ("yksi totuuden lähde").
</p>
<p>
  Data-kerros <b>uudelleenrakennetaan jokaisella sivun latauksella</b> ja varustetaan nykyisen verkkosivun 
  datapisteillä ja mahdollisesti muilla kävijään ja sen vierailuun liittyvillä tiedoilla.
</p>
<p>
  <b>Huom:</b> Yksisivuiset sovellukset (SPA) eivät lataa sivua uudelleen sivunavigoinnin välillä. Siksi data-kerroksen 
  konfigurointi yksisivuiselle sovellukselle on erilainen kuin tyypillisille verkkosivuille, joilla on sivun latauksia.
</p>
<p>
  Tallennetut tiedot edustavat alisivun ominaisuuksia tai piirteitä ja ne tallennetaan avain-arvo-pareina. Avain 
  pitää kuvailevia nimiä piirteistä, jotka paritetaan nykyisen arvon kanssa, joka tyypillisesti muuttuu käyttäjän 
  matkalla.
</p>
<SnippetJS caption="Alisivun olennaiset piirteet, kuten sen kategoria, nimi ja kieli, tallennetaan muuttujissa ja niitä voidaan myöhemmin hyödyntää analyysissä tai sääntöjen rakentamisessa TMS:ssä.">
  {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page", //kategoria
  "pageName": "sneaker overview", //nimi
  "language": "en-US", //kieli
}];`}
</SnippetJS>
<p>
  Kokonaistavoitteena on tehdä nämä datapisteet saataville tagien hallintajärjestelmässä, jotta ne voidaan lähettää
  esimerkiksi Google Analyticsin tai Facebook Ads:n kanssa ja kuvaamaan paremmin sivuston vuorovaikutuksia.
</p>
<p>
  Tämän integroinnin mahdollistamiseksi TMS pitää viittauksia avain-arvo-pareihin ja voi esimerkiksi suorittaa
  sääntöjä, kun niiden arvo muuttuu.
</p>
<p>
  <b>Esimerkki:</b> Vierailija zoomaa tuotekuvaa ja laukaisee näin tapahtuman "tuotezoom". Ilman lisätietoja, joita
  lähetetään tapahtuman yhteydessä, se ei ole kovin informatiivinen. Siksi lähetämme myös tietoja esimerkiksi tuotteen
  nimestä, kategoriasta ja hinnasta, jotta voimme analysoida tapahtumaa merkityksellisessä kontekstissa.
</p>
<p>
  Lisätiedot mahdollistavat sen varmistamisen, tapahtuuko tällaisia tuotezoomeja vain tietyissä tuotekategorioissa.
  Jos näin on, voi olla hyödyllistä lisätä enemmän kuvia muihin samaan kategoriaan kuuluviin tuotteisiin, koska
  vierailijat vaikuttavat olevan erittäin kiinnostuneita näiden tuotteiden kuvatiedoista.
</p>
<p>
  Pohjimmiltaan, me jäsennämme kaikki olennaiset tiedot kuvaileviin avaimiin ja arvoihin ja teemme ne saataville
  keskipisteessä, jossa ne voidaan helposti poimia.
</p>
<p>
  Tällaiset datapisteet ovat yleensä tärkeitä sivusisällön ominaisuuksia tai luokituksia, joita olemme keksineet
  vierailijoiden segmentoinnin perustaksi käyttäytymisen mukaan.
</p>
<p>
  Ymmärtääksesi paremmin, mitä data-kerros on, yksinkertaistettuna visualisointina, voit kuvitella Excel-taulukon.
  Taulukossa on tärkeät ominaisuudet verkkosivusta otsikkorivillä (polku, kieli, kategoria, kirjautumistila) yhdessä
  nykyisen arvon kanssa jokaiselle kohteelle.
</p>
<ImgContainerFixed width="449px">
  <ImgScreenshot
    src="data-layer/data-layer-structure-excel-example.png"
    alt="Yksinkertaistettu data-kerrosesimerkki Excelissä"
    className="article-img"
    caption="Voit kuvitella data-kerroksen yksinkertaisena taulukkona, jossa on sarakeotsikot"
  />
</ImgContainerFixed>
<H as="h2">Mitkä ovat data-kerroksen käytön hyödyt?</H>
<p>
  Vierailijan navigoidessa sivustolla tapahtuu monia käyttäjävuorovaikutuksia: painikkeiden klikkauksia, lomakkeiden
  täyttämistä tai katsottuja videoita.
</p>
<p>
  Jos nämä vuorovaikutukset antavat meille mahdollisuuden tehdä johtopäätöksiä käyttäjän sitoutumisesta, ne lähetetään
  esimerkiksi Google Analyticsiin yhdessä muiden kuvailevien tietojen kanssa vierailijasta, istunnosta, tapahtumasta
  itsestään tai HTML-elementistä, jonka kanssa he olivat vuorovaikutuksessa.
</p>
<p>
  <u>Ja tämä on olennainen asia</u>: Lisätiedot näiden vuorovaikutusten kuvaamiseksi tulevat eri tietolähteistä,
  esimerkiksi <b>frontendistä, tietokannasta</b> tai ulkoisesta <b>API:sta</b>.
</p>
<p>
  Ymmärtääksemme data-kerroksen hyödyt, meidän on ensin ymmärrettävä haasteet, jotka ilmenevät, kun tarvitsemme
  tietoja useista tietolähteistä.
</p>
<p>Tarkastellaanpa esimerkkiä, jossa keräämme tietoja tällaisista lähteistä ja mietitään sitä:</p>
<p>
  <b>Esimerkki:</b> Vierailija ostaa tuotteen verkkosivustolla. Seuraavat ulottuvuudet voivat olla mielenkiintoisia:
</p>
<ul
// TODO: show list in 2 columns, problem: looks good on desktop but not on mobile
// style={{ columns: "2", "-webkit-columns": "2", "-moz-columns": "2" }}
>
  <li>tuotteen nimi</li>
  <li>tuotteen hinta</li>
  <li>tuotteen koko</li>
  <li>tuotteen kategoria</li>
  <li>tuotteen väri</li>
  <li>ostoskoriarvo</li>
  <li>brändi</li>
  <li>ensimmäinen ostos</li>
  <li>asiakassegmentti</li>
  <li>asiakasalennus</li>
  <li>sukupuoli</li>
  <li>maa</li>
</ul>
<p>
  Oston jälkeen vierailijat päätyvät kiitossivulle, joka listaa kaikki ostoksen ja toimitusosoitteen tiedot.
</p>
<p>
  <b>Frontend</b>: Jotta voisimme lähettää tuotetiedot, ostoskorin arvon ja brändin yhdessä tapahtuman kanssa,
  voimme mahdollisesti kerätä ne kiitossivulta.
</p>
<p>
  Tärkein haaste tietojen keräämisessä verkkosivulta on, että tiedot on oltava saatavilla jokaisella sivulla, jossa
  vuorovaikutus tapahtuu. Tämä on harvoin todellisuudessa mahdollista.
</p>
<p>
  On suositeltavaa mitata mahdollisimman monia samoja ulottuvuuksia kaikkien verkkosivuston vuorovaikutusten
  yhteydessä, jotta vuorovaikutuksia voidaan myöhemmin vertailla data-analyysin aikana. Siksi, jos seuraamme tätä
  lähestymistapaa, on todennäköistä, että muilla sivuilla ei ole lueteltu <i>tuotetietoja, ostoskorin arvoa ja
  brändin nimeä</i> lähetettäväksi muiden tapahtumien yhteydessä.
</p>
<p>
  Jos tarvittavat tiedot eivät ole saatavilla muilla sivuilla, haluamme välttää kaikkien näiden tietojen lisäämistä
  sisältöön vain analytiikkaa varten. <b>Siksi käytämme data-kerrosta</b>. Se tekee tiedot saataville
  kerättäväksi, riippumatta siitä, ovatko ne näkyvissä sivulla vai eivät. Se on kirjaimellisesti kerros tietoja,
  joka sijaitsee minkä tahansa alisivun päällä ja tarjoaa tarvittavat tiedot.
</p>
<p>
  Toinen haaste tietojen keräämisessä frontendistä on, että se lopulta rikkoutuu. Kun sivuja muutetaan ja nämä
  muutokset vaikuttavat kerättyjen elementtien HTML-rakenteeseen, tietojen keruu rikkoutuu. Erityisesti suurissa
  yrityksissä sivuilla tapahtuu usein muutoksia ja useat tiimit työskentelevät niillä tietämättä, ovatko jotkin
  HTML-elementit tarpeellisia tietojen keruuta varten. Siksi mikä tahansa tietojen kerääminen frontendistä
  rikkoutuu jossain vaiheessa usein päivitettävillä verkkosivustoilla.
</p>
<p>
  Data-kerros hyödyntää tätä lähestymistapaa ja mahdollistaa käyttäjätietojen hakemisen turvallisella ja
  yksinkertaisella tavalla.
</p>
<p>
  <b>Tietokanta</b>: Asiakastietojen (asiakassegmentti, alennus, sukupuoli ja ensimmäinen ostos) kerääminen voi
  olla hieman hankalaa: Asiakastiedot olisi joko lähetettävä palvelimen vastauksen mukana tai erillisen API:n
  kautta.
</p>
<p>
  Mutta koska kyseessä ovat yksityiset tiedot, keräyksen on oltava todennettu tietosuojasyistä. Tämä tarkoittaa,
  että API-pyyntöä ei voida käsitellä selaimessa, koska API-avain olisi muuten kokeneiden käyttäjien
  löydettävissä.
</p>
<p>
  Siksi paras ratkaisu on lähettää tiedot yhdessä palvelimen vastauksen kanssa verkkosivuston kirjautumis-
  todennuksen perusteella. <br />
  Kun käyttäjä on kirjautunut sisään, data-kerros täytetään tietokannasta saatavilla tiedoilla. Ilman
  kirjautumista mitään arkaluonteisia tietoja ei paljasteta.
</p>
<p>
  <b>API</b>: Maatiedot, kuten maa, voidaan hakea ulkoisesta palvelu-API:sta.
</p>
<p>
  Kuitenkin sama haaste kuin tietokannasta tietoja haettaessa ilmenee: Kaikki API-pyynnöt frontendistä tai tagien
  hallintajärjestelmästä vaativat API-avaimen, jota ei turvallisuussyistä pitäisi käsitellä selaimessa.
</p>
<p>
  Toinen haittapuoli API:en käytössä tietojen keräämisessä, erityisesti tapahtumien yhteydessä, on se aika, joka
  kuluu tietojen palautumiseen. Jos käyttäjä navigoi toiselle sivulle ennen kuin tiedot saapuvat, riski menettää
  tapahtuman.
</p>
<p>Yhteenvetona hyödyt nopeasti:</p>
<H as="h3">Hyödyt</H>
<ul>
  <li>Tiedot ovat saatavilla, riippumatta siitä, ovatko ne näkyvissä sivulla</li>
  <li>Luotettava tietojen keruu</li>
  <li>Arkaluonteisten tietojen turvallinen keruu</li>
  <li>Asynkronisten tietopyyntöjen tietojen menetyksen vähentäminen</li>
</ul>
<H as="h2">Miksi todennäköisesti tarvitset sen</H>
<p>
  Luomalla data-kerroksen, JavaScript-objekti tehdään saataville selaimen globaalissa scopessa jokaisella
  sivulatauksella.
</p>
<p>
  Sen sisältämät tiedot voivat tulla tietokannastasi, frontendistä tai API:sta, joten tietojen kerääminen näistä
  lähteistä tulee luotettavaksi, turvalliseksi ja riippumattomaksi sivun HTML:stä.
</p>
<p>
  Tietokannan tiedot voidaan tehdä saataville minkä tahansa sivuston alisivun yhteydessä ilman suurta vaivaa
  olematta näkyvissä sisällössä.
</p>
<p>
  Edellä mainituista syistä suosittelen yleensä asiakkaille data-kerrosten toteuttamista, jos he ovat vakavissaan
  data-analyysitavoitteidensa suhteen. Tietojen laadun, luotettavuuden ja siihen liittyvien pitkän aikavälin
  aikahyötyjen edut oikeuttavat korkeammat toteutuskustannukset.
</p>
<p>
  Verkkosivuston analytiikan lopullinen tavoite on tehdä tietoon perustuvia liiketoimintapäätöksiä, joten tietojen
  laatu pitäisi olla etusijalla.
</p>
<p>
  Nyt tarkastellaan eri vaihtoehtoja ja joitain toteutusesimerkkejä ennen kuin sukellamme suunnittelu- ja
  toteutusvaiheeseen.
</p>
<H as="h2">Data-kerros Adobe Analyticsille, DTM:lle, Launchille ja Tealiumille</H>
<p>
  Data-kerrokset voivat olla eri rakenteisia. Yleisesti erottelemme ne, joilla on{" "}
  <b>objektiin perustuva rakenne</b> ja <b>taulukkoon perustuva rakenne</b>.
</p>
<p>
  World Wide Web Consortiumin (W3C) mukaan 
  <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
    data layer määritelmä
  </a>{" "}
  noudattaa JavaScript-objektin syntaksia. Sitä kutsutaan epävirallisesti lyhenteellä <b>CEDDL</b> (Customer Experience Digital Data Layer).
</p>
<p>Voit myös sisäistää muita objekteja tai taulukoita siihen:</p>
<SnippetJS caption="Esimerkki data-kerroksesta Adobe Analyticsille objektipohjaisella rakenteella, noudattaen W3C:n määritelmää.">
  {`
window.digitalData = {
  pageName: "sneaker overview",
  destinationPath: "/en/sneakers",
  breadCrumbs: ["home","sneakers"],
  publishDate: "2020-07-01",
  language: "en-US"
};`}
</SnippetJS>
<p>
  <b>Adobe Analytics, Adobe Launch</b> ja Tealium noudattavat CEDDL-rakennetta. Yllä olevassa esimerkissä tallennamme
  tietoja objektiin nimeltä <code>digitalData</code>. Nimi ei ole standardoitu ja se voidaan valita vapaasti, mutta 
  sinun täytyy ilmoittaa nimi tagien hallintajärjestelmässä.
</p>
<p>
  Tietojen muuttamiseen on useita vaihtoehtoja (kuten kaikilla JS-objekteilla), mutta yksinkertaisin tapa on vain 
  ylikirjoittaa arvot:
</p>
<SnippetJS caption="Lisätäksesi tietoja data-kerrokseen, yksinkertaisesti ylikirjoita olemassa olevat tiedot kuten minkä tahansa tavallisen JavaScript-objektin kanssa.">
  {`window.digitalData.language = "de-DE";`}
</SnippetJS>
<p>
  Objektipohjaisen rakenteen keskeinen ajatus on, että ne ladataan kerran per sivulataus, mutta niitä ei muuteta paljon 
  käyttäjän vuorovaikutuksen perusteella. Tiedot ovat enimmäkseen <b>staattisia</b>.
</p>
<p>
  Tapahtumaseuranta ei perustu tapahtumiin, jotka syötetään data-kerrosobjektiin. Tapahtumat seurataan erillisellä 
  <b>seurantakirjastolla</b> lähettääkseen ne edelleen analytiikka-alustalle, esim. Adobe Analyticsille. Kun 
  tapahtumaseurantakoodi suoritetaan, data-kerrosobjekti lähetetään kokonaisuudessaan mukana ja sitä voidaan käyttää 
  data-analyysin aikana.
</p>
<SnippetJS caption="Tapahtumaseuranta Adobelle objektipohjaisella data-kerrosrakenteella käsitellään _satellite-kirjaston kautta.">
  {`
//Tapahtuma valitulla värillä
_satellite.setVar("sneaker color", "black");
_satellite.track("select color"); 

`}
</SnippetJS>
<H as="h2">Käytä Adobe Launchia taulukkopohjaisella data-kerroksella</H>
<p>
  Voit helposti käyttää Adobe Launchia myös taulukkopohjaisella rakenteella. Adobe Launch -laajennus{" "}
  <b>Data Layer Manager</b> tekee sen mahdolliseksi.
</p>
<p>Tässä on muutamia linkkejä lisäresursseihin taulukkopohjaisen version käyttämisestä Adobe Launchin kanssa:</p>
<ul>
  <li>
    Jim Gordonin{" "}
    <a
      href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
      rel="noopener"
      target="_blank"
    >
      Demo Data Layer Managerin käytöstä Adobe Launchin kanssa
    </a>
  </li>

  <li>
    <a
      href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
      rel="noopener"
      target="_blank"
    >
      Data Layer Manager
    </a>{" "}
    laajennus ja{" "}
    <a
      href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
      rel="noopener"
      target="_blank"
    >
      dokumentaatio
    </a>
  </li>
</ul>
<H as="h2">Data-kerros Google Tag Managerille, Matomolle ja Piwik Pro:lle</H>
<p>
  <b>Data-kerros Google Tag Managerille</b>, Matomolle ja Piwik Pro:lle on taulukkopohjainen ja sitä kutsutaan 
  epävirallisesti tapahtumapohjaiseksi data-kerrokseksi <b>(EDDL)</b>.
</p>
<p>
  Tiedot käsitellään myös objekteina, mutta GTM data-kerroksen yleinen rakenne on <b>taulukko, jossa on objekteja</b>.
</p>
<SnippetJS caption="Koodiesimerkki GTM data-kerroksen lisäämisestä">
  {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];
`}
</SnippetJS>
<p>
  Seurantalogiikka taulukkopohjaisella rakenteella on erilainen: Uudet tiedot tai muutokset syötetään siihen käyttämällä{" "}
  <code>dataLayer.push()</code>. Joten <b>push data-kerrokseen</b> voi sitten laukaista tagien suoritukset tagien hallintajärjestelmässä.
</p>
<p>
  Perusero objektipohjaiseen rakenteeseen on se, että muutokset lähetetään yleensä tapahtuman yhteydessä ja säännöt laukaistaan 
  näiden muutosten perusteella ilman lisäkirjastoa, vain tarkkailemalla, muuttuuko data-kerros taulukko.
  <br />
  Koska mitään muuta kirjastoa, kuten <code>_satellite</code>, ei tarvita, tarvitsemme yhden riippuvuuden vähemmän.
</p>
<p>
  Toinen taulukkopohjaisen lähestymistavan ominaisuus on, että tiedot muuttuvat melko usein käyttäjän matkalla, koska mikä tahansa 
  käyttäjän vuorovaikutus voi muuttaa data-kerroksen muuttujia.
</p>{" "}
<p>
  Joten taulukkopohjainen data-kerros on tapahtumaseurannan perusta ja käsittelee tietoja joustavammin, kun taas objektipohjainen 
  toimii pikemminkin staattisena tietovarastona.
</p>
<p>
  Tämän joustavuuden ansiosta taulukkopohjaisen data-kerroksen rakenteen sanotaan olevan sopivampi yhden sivun sovelluksille (SPA).
  <br />
  Voit kuitenkin myös seurata SPA:ita objektipohjaisella data-kerroksella, se vain vaatii muutaman rivin koodia enemmän ja 
  mahdollisesti muutamia reunaehtojen ratkaisuja.
</p>
<p>
  Jos olet projektin alussa ja sinulla on valinnanvaraa, suosittelen taulukkopohjaista data-kerrosta.
  <br />
  Jo olemassa olevan asetuksen muuttaminen objektirakenteesta taulukkoon on kuitenkin tarpeetonta.
</p>
<H as="h2">Sisällönhallintajärjestelmät, joissa on data-kerros mukana</H>
<p>
  <b>WordPress</b>-käyttäjillä on helppoa, koska he voivat käyttää{" "}
  <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
    tätä laajennusta
  </a>{" "}
  Google Tag Managerin toteuttamiseen yhdessä esikonfiguroidun data-kerroksen kanssa.
</p>
<p>Se täyttyy automaattisesti kategorioilla, kirjoittajien nimillä, julkaisupäivillä ja hakutermeillä.</p>
<p>
  Datapisteitä voi tarkistaa tai poistaa valinnan laajennuksen asetuksissa. Lisäksi laajennus tarjoaa 
  esikonfiguroidut tapahtumat yleisimpien lomakelaajennusten lomakkeiden lähetyksille.
</p>
<p>
  Jos olet verkkokaupan omistaja ja käytät <b>WooCommercea</b> WordPressille, voit toteuttaa{" "}
  <b>klassisen e-commerce data-kerroksen</b> sekä <b>enhanced e-commerce data-kerroksen</b> samalla laajennuksella, 
  mikä on varsin tehokasta.
</p>
<p>
  <b>WordPress</b>-käyttäjät, jotka haluavat käyttää <b>Tealiumia</b>, voivat käyttää laajennusta{" "}
  <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
    Tealiumille
  </a>
  .
</p>
<p>
  <b>Drupalilla</b> on myös{" "}
  <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
    laajennus
  </a>
  .
</p>
<p>
  <b>Wix</b> ja <b>Squarespace</b> -käyttäjät voivat toteuttaa Google Tag Managerin alustan työkalujen kautta, mutta 
  heidän on toteutettava data-kerroskoodi manuaalisesti.
</p>
<H as="h2">Data-kerroksen toteutus</H>
<p>
  Joten miten toteutat data-kerroksen? - Koska suunnittelu ja toteutus vaativat tietämystä digitaalisesta analytiikasta, 
  frontend-kehityksestä ja backend-kehityksestä, toteutus tehdään yleensä web-toimiston kautta yhdessä{" "}
  <Link to="/fi/google-analytics-konsultti">analytiikkakonsultin</Link> kanssa.
</p>
<p>
  Analytiikkakonsultti ohjeistaa web-toimistoa ja johtaa projektia, kunnes toteutus on onnistuneesti validoitu. 
  Sen jälkeen tagien hallintajärjestelmä ja analytiikkatyökalut konfiguroidaan.
</p>
<p>
  Jos olet kiinnostunut ja osaat hieman JavaScriptiä, voit toteuttaa sen itse seuraavan <b>toteutusoppaan</b> avulla.
</p>
<p>Toteutus käy läpi kolme vaihetta:</p>
<ol>
  <li>
    <Link to="/fi/data-kerros">Data-kerroksen suunnittelu</Link>
  </li>

  <li>
    <Link to="/fi/data-kerros">Toteutus</Link>
  </li>

  <li>
    <Link to="/fi/data-kerros">Debuggaus</Link>
  </li>
</ol>
<H as="h3">1. Data-kerroksen suunnittelu</H>
<p>
  Suunnitteluvaiheessa määritellään, mitä vuorovaikutuksia pitäisi mitata ja mitkä ulottuvuudet.
</p>
<p>
  Mitkä tahansa vierailijan, istunnon, sivun, tuotteen tai tapahtuman attribuutit voivat olla kiinnostavia data-analyysin aikana ja 
  ne tulisi ottaa huomioon data-kerroksen arkkitehtuurissa.
</p>
<p>
  Päätettäessä, mitä sisällyttää, aloita lopputuloksesta ja kysy itseltäsi, mitkä liiketoiminnan kriittiset kysymykset täytyy 
  ratkaista ja rajaa siihen liittyvät datapisteet.
</p>
<p>
  Seuraava askel on selvittää, miten nämä datapisteet täytyy jäsentää ja mitkä ulottuvuudet ovat tärkeimpiä lisätä.
</p>
<p>
  <b>Esimerkki</b>: Kielikoulu, joka käyttää WordPress-verkkosivustoa useilla kielillä, haluaa tietää verkkosivustonsa 
  kävijöiden äidinkielen ja minkä vieraan kielen he ovat eniten kiinnostuneita. Tavoitteena on mahdollisesti käynnistää 
  verkkomainontaa Facebook Ads:n kautta, kohdistuen samankaltaisilla attribuuteilla varustettuihin väestöryhmiin.
</p>
<p>
  Seuraavana askeleena meidän tulisi määritellä kaikki olennaiset tiedot eri tyyppisillä sivuilla (etusivu, kurssisivut, meistä, 
  yhteystiedot ja uutiset). Yksinkertaistamiseksi tarkastellaan mielenkiintoisimpia sivuja ja keskitytään etusivuun ja 
  kurssisivuihin.
</p>
<ImgContainerFixed width="400px">
  <ImgScreenshot
    src="data-layer/data-layer-example-language-school.png"
    alt="Data-kerroksen suunnitteluesimerkki kielikoulun verkkosivustolle"
    caption="Esimerkki data-kerroksen suunnittelusta kielikoulun etusivulle ja kurssisivuille"
  />
</ImgContainerFixed>
<p>
  <b>Esimerkki</b>: Taulukkopohjainen Google Tag Manager -data-kerros kielikoululle
</p>
<SnippetJS caption="Koodiesimerkki GTM data-kerroksen aloittamiseksi kielikouluesimerkille. Huomaa, että numerot on ilmoitettu merkkijonoina.">
  {`
window.dataLayer = window.dataLayer || [{
  "language": "de", //Käyttöliittymän kieli
  "sessionDuration": "182", //Istunnon kesto sekunteina
  "languageIntent": "es", //Eniten vieraillut kurssikieli
  "pageType": "kurssisivu",
  "courseName": "Spanish A1 - Beginner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Kesto viikkoina
}];
`}
</SnippetJS>
<p>
  <b>Esimerkki</b>: Objektipohjainen data-kerros Adobe Launchille
</p>
<SnippetJS caption="Data-kerroksen alustus Adobe Launchille tai Adobe DTM:lle.">
  {`
window.digitalData = window.digitalData || {
  "language": "de", //Käyttöliittymän kieli
  "sessionDuration": 182, //Istunnon kesto sekunteina
  "languageIntent": "es", //Eniten vieraillut kurssikieli
  "pageType": "kurssisivu",
  "courseName": "Spanish A1 - Beginner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Kesto viikkoina
};
`}
</SnippetJS>
<H as="h3">2. Toteutus</H>
<p>
  Data-kerrokset on toteutettava jokaisella verkkosivuston alisivulla. Yllä olevat koodiesimerkit näyttävät kuitenkin lopullisen 
  <b>lasketun tilan</b>.
</p>
<p>
  Toteutuksen aikana datapisteet on ensin lähdettävä laskettaviksi lopulliseen tilaansa, joten varsinainen lähde näyttää hieman erilaiselta.
</p>
<p>Realismin vuoksi oletan kuitenkin seuraavaa:</p>
<ul>
  <li>
    Istunnon kesto ja kieli-intressi kerätään mukautetun JavaScriptin avulla ja tallennetaan selaimen paikalliseen muistiin.
  </li>
  <li>
    Kieli, sivutyyppi ja kurssitiedot voidaan hakea tietokannasta palvelimen vastauksen kautta ja tehdä saatavilla kurssipohjilla ja 
    etusivulla.
  </li>
</ul>
<p>Tietokerroksen lähdekoodi backendissä edellä mainittujen oletusten mukaan näyttäisi tältä:</p>
<SnippetJS caption="Esimerkkilähdekoodi data-kerrokselle ennen laskentaa.">
  {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent,
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseLang": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
</SnippetJS>
<H as="h4">Tapahtumaseuranta data-kerroksen push-toiminnolla</H>
<p>Voit lähettää tapahtumia GTM data-kerrokseen käyttämällä sen push-metodia ja kirjaimellisesti puskea tapahtumat siihen.</p>
<SnippetJS caption="Data-kerroksen push-esimerkki tapahtumaseurantaa varten">
  {`
window.dataLayer.push({
  "event": "kurssivaraus", 
  "alkamisviikko": "24"
});
`}
</SnippetJS>
<p>
  <b>event</b> -avainsana on erityinen avainsana ja sitä voidaan käsitellä <b>mukautettuna tapahtumana</b> GTM-konteinerista.
</p>
<p>
  Tagien hallintajärjestelmä tarkkailee data-kerrosta ja suorittaa tagin heti, kun siihen lähetetään ennalta määritelty mukautettu tapahtuma.
</p>
<p>Tapahtuman lisäämisen jälkeen TMS voi esimerkiksi lähettää tapahtuman Google Analyticsiin.</p>
<p>
  Kaikki asiaankuuluvat tiedot kontekstin antamiseksi (nimi, kieli, kielitaso, kurssin kesto) ovat saatavilla ja ne voidaan lähettää tapahtuman mukana, esimerkiksi kurssin alkamisviikko.
</p>
<p>
  Objektipohjaisessa data-kerroksessa sama tapahtuma lähetettäisiin suoraan Adobe Analyticsiin heidän oman tapahtumaseurantakirjaston kautta.
</p>
<p>Adobe Launchin esimerkkikoodi näyttäisi tältä:</p>
<SnippetJS caption="Adobe Launch -esimerkki tapahtumaseurannasta. Huomaa, että tiedot lähetetään suoraan Adobe Analyticsiin menemättä ensin data-kerroksen läpi">
  {`
_satellite.setVar("alkamisviikko", 24);
_satellite.track("kurssivaraus");
`}
</SnippetJS>
<H as="h4">Koodin sijoittaminen lähdekoodiin</H>
<p>
  Data-kerroskoodi tulisi lisätä sivun <code>{`<head>`}</code> -osaan ennen tagien hallintajärjestelmää.
</p>
<p>
  Tällä järjestyksellä varmistat, että se on jo laskettu, kun tagien hallintajärjestelmä haluaa käyttää sitä.
</p>
<p>
  <b>Esimerkki</b>: Sijoittaminen lähdekoodiin
</p>
<ImgScreenshot
  src="data-layer/positionierung-data-layer-gtm.png"
  alt="data-kerroksen sijoittaminen ennen TMS-tagia lähdekoodissa"
  caption="Data-kerros tulisi sijoittaa <head>-osioon ennen tagien hallintajärjestelmää varmistaaksesi, että sen tiedot on jo ladattu, kun TMS haluaa käyttää niitä."
/>
<H as="h3">3. Debuggaus</H>
<p>
  Yleisimmät menettelytavat data-kerroksen debuggaamiseksi ovat sivulatausten tai tapahtumien simulointi varmistaaksesi, että kaikki datapisteet täyttyvät oikeilla tiedoilla.
</p>
<p>
  Koska se on globaali, pääset siihen käsiksi selaimen konsolista ja voit yksinkertaisesti tulostaa kaikki arvot konsoliin (olettaen, että standardin nimeämiskäytännöt on otettu käyttöön):
</p>
<p>
  <b>Verkkosivut, joissa on GTM</b>
</p>
<p>
  <code>Object.assign(...dataLayer)</code>
</p>
<p>
  <b>Verkkosivut, joissa on Adobe Launch tai DTM</b>
</p>
<p>
  <code>digitalData</code>
</p>
<p>
  <b>Tealium</b>
</p>
<p>
  <code>utag.data</code> tai <code>utag_data</code>
</p>
<p>
  Google Tag Manager sisältää jopa oman <strong>debuggaustilan</strong>. Voit aktivoida sen GTM-käyttöliittymästä klikkaamalla <b>esikatselu</b> oikeassa yläkulmassa.
</p>
<ImgScreenshot
  src="data-layer/gtm-activate-debugger-mode.png"
  alt="Google Tag Managerin näyttökuva, joka näyttää debuggaustilan aktivointipainikkeen"
  caption={`Voit aktivoida GTM:n debuggaustilan klikkaamalla esikatselupainiketta. Kun sitten vierailet verkkosivustollasi asennetulla GTM-kontainerilla, debuggausikkuna ilmestyy selaimen alaosaan.`}
/>
<ImgScreenshot
  src="data-layer/gtm-debugger-mode.png"
  alt="GTM debuggausikkunan näyttökuva"
  caption="Voit tarkastella kaikkia data-kerrosmuuttujia ja nähdä niiden nykyiset arvot vuorovaikutuksessa sivuston kanssa. Lisäksi voit seurata tapahtumien sarjaa, jotka syötetään. Klikkaa jokaista tapahtumaa nähdäksesi, mitä muutoksia tapahtuma aiheutti."
/>
<p>
  Jos sinulla ei ole pääsyä tagien hallintakontaineriin, mutta haluat silti debugata sitä, voit käyttää <b>chrome-laajennusta</b>.
</p>
<H as="h4">Data-kerroksen Chrome-laajennukset</H>
<p>
  Saatavilla on monia potentiaalisia laajennuksia debuggaamiseen. Suosin niitä, jotka tukevat useimpia myyjiä, jotta minun ei tarvitse vaihtaa laajennusten välillä debuggaessani toista sivustoa.
</p>
<p>Seuraavat Chrome-laajennukset ovat tällä hetkellä suosikkejani debuggaamiseen:</p>
<ul>
  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
    >
      <b>Trackie</b>
    </a>{" "}
    - Laajennus perustuu{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
    >
      Data Slayeriin
    </a>{" "}
    ja on avoimen lähdekoodin. Siinä on melko hyvä suorituskyky ja se tukee GTM:ää, DTM:ää, Tealiumia{" "}
    <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
      ja paljon muuta
    </a>
    .
  </li>

  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
    >
      <b>Omnibug</b>
    </a>{" "}
    - Toinen yleislaajennus, joka tukee Adobe Analyticsia (DTM & Launch), sekä Matomoa, GTM:ää, Tealiumia{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
    >
      ja muita
    </a>
    .
  </li>
</ul>
<H as="h4">Chrome-laajennukset Google Analyticsin ja GTM:n debuggaamiseen</H>
<ul>
  <li>
    <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
      <b>GTM/GA Debug</b>
    </a>{" "}
    - laajennuksen aktivoinnin jälkeen saatavilla on uusi välilehti Chrome DevToolsissa, kun avaat ne
    (F12 Windowsissa ja CTRL+SHIFT+i Macissa). Kaikki asiaankuuluvat tiedot näytetään graafisessa käyttöliittymässä 
    ja ne päivittyvät, kun selaat verkkosivustoa.
  </li>
  <li>
    <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
      <b>Adswerve - dataLayer Inspector+</b>
    </a>{" "}
    - kirjaa kaikki asiaankuuluvat datapisteet selaimen konsoliin. Aktivoi "preserve log" konsoliasetuksissa, 
    jotta lokit säilyvät sivunavigoinnin aikana.
  </li>
  <li>
    <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
      <b>Data Slayer</b>
    </a>{" "}
    - ehdottomasti laajennus, jossa on siistein nimi ja logo ja aikaisempi suosikkini. Se on avoimen lähdekoodin 
    yksinkertaisella käyttöliittymällä ja toimii myös Adobe DTM:n kanssa. <br />
    <u>
      Poista valinnat "use three-column layout where available", "show GA Classic tags" ja "show Floodlight tags" 
      laajennuksen asetuksissa,
    </u>{" "}
    muuten lokit muuttuvat hieman sotkuisiksi.
  </li>
</ul>
<H as="h4">Chrome-laajennukset Adobe Analyticsin, Launchin ja DTM:n debuggaamiseen</H>
<ul>
  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
    >
      <b>Launch & DTM Switch</b>
    </a>{" "}
    - mahdollistaa tagien hallintajärjestelmän testikirjaston tai tuotantokirjaston lataamisen ja debuggaustilan 
    aktivoimisen.
  </li>

  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
    >
      <b>Debugger for Adobe Analytics</b>
    </a>{" "}
    - aktivoi debuggaustilan. Vaihtoehtoisesti voit myös kirjoittaa <code>_satellite.setDebug(true)</code> konsoliin.
  </li>

  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
    >
      <b>Adobe Experience Cloud Debugger</b>
    </a>{" "}
    - meta-laajennus kaikkien Adobe-tuotteiden debuggaamiseen.
  </li>

  <li>
  <a
    rel="noopener"
    target="_blank"
    href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
  >
    <b>Adobe Experience Platform Debugger</b>
  </a>{" "}
  - Experience Cloud Debuggerin seuraaja, joka tarjoaa paremman yleiskuvan (tällä hetkellä vielä beta-vaiheessa).{" "}
</li>

<li>
  <a
    rel="noopener"
    target="_blank"
    href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
  >
    <b>Tealium Data Layer Debugger</b>
  </a>{" "}
  - yksinkertaiset taulukot kaikista nykyisistä arvoista.
</li>
</ul>
<H as="h2">E-Commerce Data Layer</H>
<p>
  Verkkokauppojen data-kerrokset ovat laajempia ja niiden rakenne on monimutkaisempi. Niiden täytyy sisältää enemmän 
  tietoa ja hallita enemmän tapahtumia.
</p>
<p>Siksi verkkokauppasivuston suunnittelu ja toteutus vie huomattavasti enemmän aikaa.</p>
<p>
  Esimerkiksi Google Analyticsin verkkokaupparaportti ei näytä mitään tietoja, jos toteutus ei noudata heidän{" "}
  <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=de">
    e-commerce data layer -dokumentaatiotaan
  </a>
  .
</p>
<p>
  Suuret verkkokaupat vaativat kuitenkin vielä kehittyneempiä seurantajärjestelmiä. Ne toteuttavat{" "}
  <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
    data-kerroksen parannettua e-commercea varten
  </a>
  , mikä mahdollistaa vielä enemmän toimintoja Google Analyticsissa.
</p>
<p>
  Sinun täytyy noudattaa Google Analyticsin toteutusohjeita tarkasti, jotta verkkokaupparaportit toimivat. Tämä koskee 
  data-kerroksen rakennetta ja myös muuttujien nimiä.
</p>
<p>
  Jos valitset toisen analytiikka-alustan verkkokauppaa varten, voit suunnitella rakenteen haluamallasi tavalla.
</p>
<H as="h2">Milloin data-kerrosta ei tarvita?</H>
<p>
  Kuten kaikessa, myös data-kerroksen toteuttaminen ei aina ole perusteltua.
</p>
<p>
  Edellä olevissa esimerkeissä tarkastelimme tapauksia, joissa haimme tietoja eri lähteistä (Frontend, Backend, API) 
  ja ratkaisimme ongelmia, jotka syntyivät työskennellessämme erilaisten tietolähteiden kanssa.
</p>
<p>
  Monet verkkosivustot (niin sanotut esitesivustot) eivät kuitenkaan edes sisällä kirjautumistoimintoa eikä niillä ole 
  tietokantaa.
</p>
<p>
  Toinen tärkeä tekijä on, kuinka usein sivustolle tehdään muutoksia. Monet sivustot tarkistavat sisältönsä tai lisäävät 
  toimintoja harvoin. Näen jopa yrityksiä, jotka ylläpitävät yksinkertaisia esitesivustoja, joissa on noin 50 alisivua ja 
  yhteydenottolomake vaikeimpana konversiona.
</p>
<p>
  Koska tällaiset sivustot todennäköisesti tarvitsevat tietoja vain front-endistä data-analyysiä varten, ne voisivat 
  pärjätä yksinkertaisella analytiikka-asetuksella ilman data-kerrosta. Se ei tekisi keruusta paljonkaan kestävämpää 
  tai turvallisempaa, joten sen edut vähenevät. Tällaisissa olosuhteissa hyödyt eivät oikeuta toteutuksen lisävaivaa.
</p>
<p>
  Tyypillisiä esimerkkejä, jolloin data-kerrosta ei tarvita, ovat esitesivustot tai sisältösivustot, joissa on rajoitettu 
  määrä tai ei juuri lainkaan vaikeita konversioita. Yleensä tällaiset sivustojen omistajat ovat yksinkertaisesti 
  kiinnostuneita kategorisoimaan käyttäjien sitoutumista sisältöosioidensa tai yrityksen sisäisten luokitusten perusteella.
</p>
<p>
  Tällaiset vaatimukset voidaan saavuttaa edistyneellä JavaScriptillä ja harkitulla sisällön jäsentelyjärjestelmällä.
</p>
<p>
  Heti kun tiedonkeruu front-endistä alkaa säännöllisesti rikkoutua ja erityisesti silloin, kun tietokantaa tulisi 
  käyttää, suositellaan data-kerroksen käyttöönottoa.
</p>
<p>
  Vaihtoehtoiset ratkaisut ovat usein vain väliaikaisesti tyydyttäviä johtuen yhä kasvavista analytiikka-ambitioista ja 
  säännöllisesti rikkoutuvasta tiedonkeruusta. Lisäksi kaikki mukautetut ratkaisut ovat yleensä vaikeita siirtää 
  toiselle toimistolle.
</p>
<p>
  Data-kerroksella on hyvät mahdollisuudet selviytyä ajan testistä, koska se on jo vakiintunut käsite web-analytiikan 
  alalla, joten web-toimistoilla on kasvavaa kokemusta sen toteuttamisesta ja ylläpidosta.
</p>
<H as="h2">Yhteenveto</H>
<p>
  Data-kerros on ammattimaisen analytiikka-asetuksen kultastandardi. Se parantaa tiedon laatua ja siten parantaa 
  data-analyysiä kokonaisuutena samalla täyttäen turvallisuusvaatimukset.
</p>
<p>
  Kunnianhimoisille verkkosivustojen omistajille, jotka haluavat aloittaa vakavan data-analyysin, se on helpoin ja 
  kestävin ratkaisu.
</p>
<p>
  Jos sinulla on valinnanvaraa, toteuta taulukkopohjainen rakenne, koska sinulla on vähemmän riippuvuuksia ja voit 
  käyttää sitä kaikentyyppisillä verkkosivustoilla.
</p>
<p>
  Sisältösivustot ovat kuitenkin niin rajoitettuja toiminnoiltaan ja yleensä tuovat vain rajoitetun määrän 
  vaikeita konversioita, joten data-kerroksen voi mahdollisesti unohtaa. Tämä pätee erityisesti, jos kaikki 
  tarvittavat tiedot ovat saatavilla sivuilla tai ne voidaan tehdä saatavilla jonkin kiertotien kautta.
</p>
<p>
  Jos haluat toteuttaa sen itse, tekeminen WordPress-sivustolla on todennäköisesti helpointa. Kaikki 
  kehittyneemmät vaatimukset eivät kuitenkaan todennäköisesti ole ajan arvoisia ja riskinä on tyytymätön lopputulos.
  <br />
  Siksi toteuttaminen analytiikkakonsultin avulla on yleensä paras tapa edetä, sillä se säästää aikaa ja välttää 
  tarpeettomat riskit.
</p>
<p>
  Suosittelen asentamaan jonkin mainituista Chrome-laajennuksista tarkastellaksesi isompien verkkosivustojen 
  data-kerroksia. Se on yleensä hyvä inspiraation lähde ja tuottaa mielenkiintoisia KPI:itä, joita voi mahdollisesti 
  integroida omaan analytiikka-asetukseen 😉.
</p>
<H as="h2">Erilaisten TMS-toimittajien data-kerrosdokumentaatio</H>
<ul>
  <li>
    <b>Google Tag Manager</b>:{" "}
    <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
      Aloitus ja tietojen muuttaminen
    </a>
  </li>

  <li>
    <b>Adobe Launch</b>:{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://docs.adobe.com/content/help/en/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
    >
      Aloitus
    </a>
  </li>

  <li>
    <b>Tealium iQ</b>:{" "}
    <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
      Aloitus
    </a>{" "}
    ja{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
    >
      tietojen muuttaminen
    </a>
  </li>

  <li>
    <b>Matomo</b>:{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
    >
      Aloitus
    </a>{" "}
    ja{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
    >
      tietojen muuttaminen
    </a>
  </li>

  <li>
    <b>Piwik Pro</b>:{" "}
    <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
      Aloitus
    </a>{" "}
    ja{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
    >
      tietojen muuttaminen
    </a>
  </li>
</ul>
<H as="h2">UKK</H>
<FaqComponent data={{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Mikä on data-kerroksen esimerkki?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Artikkelissa on esimerkki data-kerroksesta. JavaScript-objekti tallentaa tietoja verkkosivustosta, tietokannasta tai ulkoisesta lähteestä keskitetysti, joustavasti ja helposti saatavilla olevalla tavalla. Esimerkki koodista data-kerroksen alustamiseksi Google Tag Managerille: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"category page\", \"pageName\": \"sneaker overview\", \"language\": \"en-US\",}];"
      }
    },
    {
      "@type": "Question",
      "name": "Mitä ovat data-kerrosmuuttujat?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Data-kerrosmuuttujat ovat avain-arvo-pareja data-kerroksessa, jotka tallentavat tiettyjä tietoja. Nämä muuttujat voivat sisältää sivun ominaisuuksia, käyttäjän käyttäytymistietoja ja paljon muuta, toimien keskitettynä tietovarastona analytiikkaa ja seurantaa varten."
      }
    },
    {
      "@type": "Question",
      "name": "Miksi käyttää data-kerrosta?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Data-kerros on olennaisen tärkeä kestävälle, joustavalle ja turvalliselle tiedonkeruulle. Se keskittää tiedot eri lähteistä, tehden niistä helposti saatavilla olevia ja johdonmukaisia eri verkkosivuilla ja käyttäjävuorovaikutuksissa. Tämä lähestymistapa parantaa tietojen laatua ja luotettavuutta, mikä on ratkaisevaa tietoon perustuvassa päätöksenteossa."
      }
    },
    {
      "@type": "Question",
      "name": "Tarvitsenko data-kerrosta?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Vaikka se ei ole aina välttämätöntä, data-kerrosta suositellaan yleensä niille, jotka ovat vakavissaan data-analyysitavoitteidensa suhteen. Se tarjoaa tiedon laatua, luotettavuutta ja pitkän aikavälin ajan säästöjä, jotka oikeuttavat korkeammat toteutuskustannukset."
      }
    },
    {
      "@type": "Question",
      "name": "Mitkä ovat data-kerroksen edut?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Data-kerroksen edut sisältävät: Tietojen saatavuus riippumatta siitä, ovatko ne näkyvissä sivulla. Luotettava tiedonkeruu. Asynkronisten tietopyyntöjen tietojen menetyksen vähentäminen. Turvallinen tiedonkeruu useista lähteistä."
      }
    },
    {
      "@type": "Question",
      "name": "Onko kaikilla verkkosivustoilla data-kerros?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Kaikilla verkkosivustoilla ei ole data-kerrosta. Sen toteutus riippuu verkkosivuston monimutkaisuudesta ja tarvittavan data-analyysin syvyydestä. Yksinkertaisilla verkkosivustoilla ei ehkä ole data-kerrosta, kun taas monimutkaisemmat sivustot, erityisesti ne, jotka keskittyvät tietoon perustuvaan päätöksentekoon, todennäköisesti sisältävät sen."
      }
    },
    {
      "@type": "Question",
      "name": "Kuinka pääsen käsiksi data-kerrokseen?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Data-kerros on globaalisti saatavilla selaimen konsolissa. Verkkosivustoilla, joilla on Google Tag Manager, voit käyttää sitä dataLayer:llä tai Object.assign(...dataLayer):llä. Adobe Launch tai DTM:llä voit käyttää sitä digitalData:llä."
      }
    },
    {
      "@type": "Question",
      "name": "Kuinka puskea data-kerrokseen?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Puskeaksesi data-kerrokseen, käytä dataLayer.push()-metodia. Esimerkiksi: window.dataLayer.push({ \"event\": \"kurssivaraus\", \"alkamisviikko\": \"24\" }); Tämä metodi käytetään uusien tietojen tai muutosten lisäämiseen data-kerrokseen. Event-avainsanaa voidaan käyttää toisen tagin suorittamisen käynnistämiseen tagien hallintajärjestelmässä."
      }
    }
  ]
}
} />
<H as="h2">Lisäresurssit</H>
<ul>
  <li>
    Simo Ahava{" "}
    <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
      data-kerroksesta GTM:ssä
    </a>{" "}
    ja{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
    >
      kuinka käsitellä tietoja siinä.
    </a>
  </li>
  <li>Lue <Link to="/fi/google-tag-manager-asennus">Google Tag Manager -oppaani</Link> ja opi sen asettaminen.</li>
  <li>
    Kevin Haagin esitys Measurecamp Berlin 2019:stä{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
    >
      Event Driven Data Layerista Adobe Analyticsissa
    </a>
  </li>
</ul>
</MainContent>
</React.Fragment>

</Layout>
);

export default DataLayer;

